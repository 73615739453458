















































































































































import CapStoreApi from "@/api/cap-store.api";
import { getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class MemberPrice extends Vue {
  @Prop() fracture!: boolean;
  @Prop() items!: any;
  spcList: any = [];
  page = 1;
  page_size = 4;
  async created() {
  }
  toTimitPage(id: any) {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${id}`);
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
      query: { time: String(getNow()), id: `${id}` },
    });
  }
}
