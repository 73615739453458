import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";
import SupplyPackageApi, {
  ISupply,
  ISupplyBox,
  ISupplyDetail,
  ISupplyShufflingMessage,
} from "@/api/supply-package.api";

@Module({
  namespaced: true,
  name: "SupplyPackageStateStore",
  store,
  dynamic: true,
})
export default class SupplyPackageStateStore extends VuexModule {
  welfare_supply: Array<ISupply> = [];
  supplyShufflingMessage: Array<ISupplyShufflingMessage> = [];
  supply: Array<ISupply> = [];

  supply_config = {
    page: 1,
    loading: false,
    finished: false,
    refreshing: false,
    skeleton: false,
  };

  @Mutation
  setSupplyConfig(config: Record<string, number | boolean>): void {
    this.supply_config = {
      ...this.supply_config,
      ...config,
    };
  }

  @Mutation
  setSupply(supply: Array<ISupply>): void {
    this.supply = supply;
  }

  @Action({ rawError: true })
  async getSupply(): Promise<void> {
    if (this.supply_config.page === 1) {
      this.setSupplyConfig({
        refreshing: true,
        finished: false,
      });
    }

    const result = await SupplyPackageApi.getSupplyList({
      page: this.supply_config.page,
      page_size: 10,
    });
    this.setSupplyConfig({
      loading: false,
      refreshing: false,
      finished: result.length <= 0,
    });
    this.setSupply([
      ...(this.supply_config.page > 1 ? this.supply : []),
      ...result,
    ]);
  }

  @Mutation
  setWelfareSupply(supply: Array<ISupply>): void {
    this.welfare_supply = supply;
  }

  @Action({ rawError: true, commit: "setWelfareSupply" })
  async getWelfareSupply(): Promise<Array<ISupply>> {
    return await SupplyPackageApi.getSupplyList({
      page: 1,
      page_size: 5,
      price: "1",
    });
  }

  @Action({ rawError: true })
  async getSupplyDetail(box_id: number): Promise<ISupplyDetail> {
    return await SupplyPackageApi.getSupplyDetail(box_id);
  }

  @Action({ rawError: true })
  async getSupplyBoxList(box_id: number): Promise<Array<ISupplyBox>> {
    return await SupplyPackageApi.getSupplyBoxList(box_id);
  }
  @Action({ rawError: true })
  async getWePlayBoxList(): Promise<Array<ISupplyBox>> {
    return await SupplyPackageApi.getWePlayBoxList();
  }

  @Mutation
  setSupplyShufflingMessage(params: Array<ISupplyShufflingMessage>): void {
    this.supplyShufflingMessage = params;
  }

  @Action({ rawError: true })
  async getSupplyShufflingMessage(
    box_id?: number
  ): Promise<Array<ISupplyShufflingMessage>> {
    const result = await SupplyPackageApi.getSupplyShufflingMessage(box_id);
    if (!box_id) {
      this.setSupplyShufflingMessage(result);
    }
    return result;
  }
}
