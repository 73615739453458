
















import { getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component({
  components: {},
})
export default class GameCard extends Vue {
  @Prop() item!:any
  @Emit()
  toOpen(){
    
  }
  toDetail(id:any){
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${id}`);
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
      query: { time: String(getNow()), id: `${id}` },
    });
  }
}
