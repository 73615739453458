



















































































































































































































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Popup, Loading, Toast } from "vant";
import GlobalStateStore from "@/store/modules/global-state.store";
import InteractionLib from "@/utils/interaction.lib";
import { throttle } from "lodash";
import { getModule } from "vuex-module-decorators";
type ICallback = (channel: number, deduction_wallet: boolean) => void;

@Component({
  components: {
    Popup,
    Loading,
  },
})
export default class MemberBuyPop extends Vue {
  @Prop() wideHeight!:string
  globalState = getModule(GlobalStateStore);
  popShow = false;
  throttleImmediatelyBuy = throttle(this.immediatelyBuy.bind(this), 1500);
  destoryList() {
    this.list = [];
  }
  async immediatelyBuy() {
    this.openMemberPage();
  }
  @Emit()
  openMemberPage() {}
  get appName(){
    if(this.globalState.packageName == 'com.vgn.gamepower'){
      return '《游戏动力用户协议》'
    }
    return '《超级蒸汽用户协议》'
  }
  list = [
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%85%8D%E8%B4%B9%402x.png",
      title: "免费大作",
      desc: "价值40元以上",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E7%A5%9E%E7%A7%98%402x.png",
      title: "神秘游戏",
      desc: "热门3A大作",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%9B%9E%E6%94%B6%402x.png",
      title: "稳赚回收",
      desc: "会费直接回本",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E4%BC%9A%E5%91%98%E4%BB%B7%402x.png",
      title: "会员价",
      desc: "金会员专属价",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E9%AA%A8%E6%8A%98%402x.png",
      title: "天天骨折",
      desc: "会员特权抢",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E8%92%B8%E6%B1%BD%E5%B8%81%402x.png",
      title: "消费返蒸汽币",
      desc: "省上加省",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%8B%8B%E7%AB%A0%402x.png",
      title: "专属勋章",
      desc: "金会员勋章",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x.png",
      title: "敬请期待",
      desc: "更多权益",
    },
  ];

  private openProtocol() {
   if (InteractionLib.isApp()) {
        if (this.globalState.packageName == "com.vgn.gamepower") {
          InteractionLib.openWebView(
            "https://wap.vgn.cn/gamepower/gamepower.html"
          );
          return;
        }
        InteractionLib.openWebView("http://api.io.vgn.cn/steam_protocol.html");
        return;
      }
      location.href = "http://api.io.vgn.cn/steam_protocol.html";
  }
  public hidden(){
    this.popShow = false;
    this.list = []
  }
 
  public show() {
    this.list = [
      {
        img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%85%8D%E8%B4%B9%402x.png",
        title: "免费大作",
        desc: "价值40元以上",
      },
      {
        img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E7%A5%9E%E7%A7%98%402x.png",
        title: "神秘游戏",
        desc: "热门3A大作",
      },
      {
        img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%9B%9E%E6%94%B6%402x.png",
        title: "稳赚回收",
        desc: "会费直接回本",
      },
      {
        img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E4%BC%9A%E5%91%98%E4%BB%B7%402x.png",
        title: "会员价",
        desc: "金会员专属价",
      },
      {
        img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E9%AA%A8%E6%8A%98%402x.png",
        title: "天天骨折",
        desc: "会员特权抢",
      },
      {
        img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E8%92%B8%E6%B1%BD%E5%B8%81%402x.png",
        title: "消费返蒸汽币",
        desc: "省上加省",
      },
      {
        img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%8B%8B%E7%AB%A0%402x.png",
        title: "专属勋章",
        desc: "金会员勋章",
      },
      {
        img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x.png",
        title: "敬请期待",
        desc: "更多权益",
      },
    ];
    this.popShow = true;
  }
}
