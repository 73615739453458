import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";
import CapStoreApi, {
  OrderRecord,
  StoreBuyProductResult,
  StoreProduct,
  StoreProductDetail,
} from "@/api/cap-store.api";
import { Toast } from "vant";
// import { initialUnencryptedStorage } from "@/store/globals";

@Module({
  namespaced: true,
  name: "CapStoreStateStore",
  store,
  dynamic: true,
  // preserveState: Boolean(initialUnencryptedStorage["CapStoreStateStore"]), // preserve state when available from local storage, else don't preserve
})
export default class CapStoreStateStore extends VuexModule {
  products: Array<StoreProduct> = [];
  product: StoreProductDetail | null = null;

  product_id: number | null = null;

  loading = false;
  finished = false;
  refreshing = false;
  page = 1;
  pageSize = 15;

  skeleton = false;
  detailSkeleton = false;
  orderSkeleton = false;
  buyProductInfo: StoreBuyProductResult | null = null;
  order: OrderRecord | null = null;

  @Mutation
  setProductBuyRecord(order: OrderRecord | null): void {
    this.order = order;
  }

  @Mutation
  setOrderSkeleton(skeleton: boolean): void {
    this.orderSkeleton = skeleton;
  }

  @Action({ rawError: true, commit: "setProductBuyRecord" })
  async getProductBuyRecord(param: {
    id: number;
    order_no: string;
  }): Promise<OrderRecord | boolean> {
    this.setOrderSkeleton(true);
    const order = await CapStoreApi.getProductBuyRecord(
      param.id,
      param.order_no
    ).catch((err) => {
      Toast.fail({ message: err.message, icon: "close", duration: 2000 });
      return false;
    });
    this.setOrderSkeleton(false);
    return order;
  }

  @Mutation
  setDetailSkeleton(skeleton: boolean): void {
    this.detailSkeleton = skeleton;
  }

  @Mutation
  setProduct(product: StoreProductDetail): void {
    this.product = product;
  }

  @Mutation
  setProductId(product_id: number | null = null): void {
    this.product_id = product_id;
  }

  @Action({ rawError: true, commit: "setProduct" })
  async getProduct(id: number): Promise<StoreProductDetail> {
    this.setProductId(id);
    this.setDetailSkeleton(true);
    const row = await CapStoreApi.getProduct(id);
    this.setDetailSkeleton(false);
    return row;
  }

  @Mutation
  setPage(param: { page: number; pageSize?: number }): void {
    this.page = param.page;
    if (param.pageSize) this.pageSize = param.pageSize;
  }

  @Mutation
  setRefreshing(refreshing: boolean): void {
    this.refreshing = refreshing;
  }

  @Mutation
  setProducts(products: Array<StoreProduct>): void {
    this.products = products;
  }

  @Mutation
  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  @Mutation
  setFinished(finished: boolean): void {
    this.finished = finished;
  }

  @Mutation
  setSkeleton(skeleton: boolean): void {
    this.skeleton = skeleton;
  }

  @Mutation
  setBuyProductInfo(info: StoreBuyProductResult): void {
    this.buyProductInfo = info;
  }

  @Action({ rawError: true, commit: "setBuyProductInfo" })
  async buyProduct({
    id,
    channel = 0,
    openid = undefined,
    deduction_wallet
  }: {
    id: number;
    channel?:number
    openid?: string;
    deduction_wallet?:boolean
  }): Promise<StoreBuyProductResult | void> {
    const loading = Toast.loading({
      message: channel === 0 ? "排队中..." : "创建中...",
      duration: 0,
    });
    const result = await CapStoreApi.buyProduct(id, channel, openid,deduction_wallet)
      .then((ret) => {
        loading.close();
        return ret;
      })
      .catch((err) => {
        loading.close();
        Toast.fail({ message: err.message, icon: "close", duration: 2000 });
        return undefined;
      });
    return result;
  }

  @Action({ rawError: true })
  async getProducts(): Promise<void> {
    if (this.page === 1) {
      this.setRefreshing(true);
      this.setFinished(false);
    }
    const result = await CapStoreApi.getProductList(this.page, this.pageSize);
    this.setLoading(false);
    this.setRefreshing(false);
    if (result.length < this.pageSize) {
      this.setFinished(true);
    }
    if (this.page > 1) {
      this.setProducts([...this.products, ...result]);
      return;
    } else {
      this.setProducts(result);
    }
  }
}
