











import { Component, Vue } from "vue-property-decorator";
import MemberCenter from "@/views/member-equity/MemberCenter.vue";
import MemberPage from "@/views/member-equity/MemberPage.vue";
import { ISupplyBox } from "@/api/supply-package.api";
import MemberApi from "@/api/member.api";
import { observer } from "@/utils/common.util";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";

@Component({
  components: {
    MemberPage,
    MemberCenter,
  },
})
export default class MemberCombine extends Vue {
  MemberPageData: any = null;
  globalState = getModule(GlobalStateStore);

  async created() {
    await this.checkMember();
  }
  mounted(){
     observer("refreshInit", async () => {
      if (!this.globalState.userInfo) {
        this.checkMember()
      }
    });
  }
  async checkMember(){
    this.MemberPageData = await MemberApi.getMemberData();
  }
}
