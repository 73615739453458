























































































































import CapStoreApi from "@/api/cap-store.api";
import { getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class MemberPriceSecond extends Vue {
  @Prop() fracture!: boolean;
  @Prop() item!: any;
  @Prop() spcId!: any;
  spcList: any = [];
  page = 1;
  page_size = 6;
  async created() {
    let rows: any = await CapStoreApi.getHotItemList(
      this.spcId,
      this.page,
      this.page_size
    ).catch(() => []);
    this.spcList = rows.list;
  }
  handleOpenDetail(id: any): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${id}`);
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
      query: { time: String(getNow()), id: `${id}` },
    });
  }
}
