




































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import GameCard from "./components/GameCardReword.vue";
import FutureList from "./components/FutureList.vue";
import DestinyCard from "@/views/member-equity/components/DestinyCard.vue";

import DestinyCartoonSame from "@/views/member-equity/components/DestinyCartoonSame.vue";
import { ISupplyBox } from "@/api/supply-package.api";
import { getModule } from "vuex-module-decorators";
import SupplyPackageStateStore from "@/store/modules/supply-package-state.store";
import SalesSnapCardList from "@/views/special-sales/components/SalesSnapCardList.vue";
import { List, Popup, Toast, Swipe, SwipeItem } from "vant";
import EmptyBox from "@/components/EmptyBox.vue";
import SearchApi from "@/api/bazaar.api";
import {
  getNow,
  momentFormat,
  momentFormatNot,
  monthday,
  observer,
} from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import MemberApi from "@/api/member.api";
import CapStoreApi from "@/api/cap-store.api";

@Component({
  components: {
    GameCard,
    DestinyCard,
    FutureList,
    DestinyCartoonSame,
    SalesSnapCardList,
    List,
    EmptyBox,
    Popup,
    Swipe,
    SwipeItem,
  },
})
export default class MemberCenter extends Vue {
  wideHigh = 0;
  wideHighStyle = "";
  activeNames = ["1"];
  checkFlag1 = false;
  checkFlag2 = false;
  checkTabFlag = false;
  loading = false;
  finished = false;
  page = 1;
  page_size = 10;
  filter = 0;
  sort = 2;
  showRecycle = false;
  showGet = false;
  supply_id: any = 0;
  @Prop() MemberPageData!: any;
  getBlindStatus: boolean = false;
  getRecycleStatus: boolean = true;
  @Watch("supply_id")
  watchsupply_id() {
    console.log("supply: =>", this.supply_id);
  }
  handleOpenBlindBox(): void {
    window.onpageshow = null;
    window.onpageshow = function () {
      location.reload();
    };
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/blind-box/${this.MemberPageData.gold_vip_box_id}`
      );
    }
    this.$router.push({
      name: "BlindBoxDetail",
      params: { id: `${this.MemberPageData.gold_vip_box_id}` },
      query: { time: String(getNow()) },
    });
  }
  async checkSuccessBlind() {
    const res = await MemberApi.checkSuccessBlind();
    return res;
  }
  toIndexDetail(index: number) {
    switch (index) {
      case 0:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(`${location.origin}/free-book`);
        }
        this.$router.push({ name: "freebook" });
        break;
      case 1:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(
            `${location.origin}/mysterious-game`
          );
        }
        this.$router.push({ name: "mysteriousgame" });
        break;
      case 2:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(`${location.origin}/sure-recycle`);
        }
        this.$router.push({ name: "surerecycle" });
        break;
      case 3:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(
            `${location.origin}/member-exclusive`
          );
        }
        this.$router.push({ name: "memberexclusive" });
        break;
      case 4:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(
            `${location.origin}/member-exclusive`
          );
        }
        this.$router.push({ name: "memberexclusive" });
        break;
      case 5:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(`${location.origin}/consume-coin`);
        }
        this.$router.push({ name: "consumecoin" });
        break;
      case 6:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(`${location.origin}/member-badge`);
        }
        this.$router.push({ name: "memberbadge" });
        break;
    }
  }
  async checkRecycleReword() {
    const res: any = await MemberApi.checkRecycleReword();
    return res;
  }
  get tabWidths() {
    const { clientWidth, offsetWidth } = document.body;
    this.tabWidth = (clientWidth || offsetWidth) - 104 - (42 + 35) + "px";
    if (this.checkTabFlag) {
      this.tabWidth = `transform:translateX(${this.tabWidth})`;
    } else {
      this.tabWidth = "";
    }
    return this.tabWidth;
  }
  get OneCard() {
    if (this.getBlindStatus) {
      return this.supplyBox.filter((item, index) => {
        if (item.id == this.supply_id) {
          return item;
        }
      });
    }
  }
  rightNowGet() {
    if (this.getRecycleStatus) return;
    this.showGet = true;
  }
  startPos: any = null;
  start(event: any) {
    let touchS = event.targetTouches[0];
    this.startPos = {
      x: touchS.pageX,
      y: touchS.pageY,
      time: new Date(),
    };
  }
  move(event: any) {
    let touchM = event.targetTouches[0];
    this.endPos = {
      x: touchM.pageX,
      y: touchM.pageY,
      time: new Date(),
    };
  }
  endPos: any = null;
  end(event: any) {
    let dir = this.getDirection(
      this.startPos.x,
      this.startPos.y,
      this.endPos.x,
      this.endPos.y
    );
    if (dir == 1 || dir == 2) {
      return;
    } else if (dir == 3) {
      this.checkTabFlag = !this.checkTabFlag;
    } else if (dir == 4) {
      this.checkTabFlag = !this.checkTabFlag;
    }
  }
  getDirection(statrX: number, startY: number, endX: number, endY: number) {
    let angx = endX - statrX;
    let angy = endY - startY;
    let result = 0;
    if (
      Math.abs(angy) > -40 &&
      Math.abs(angy) < 40 &&
      (Math.abs(angx) > 30 || Math.abs(angx) < -30)
    ) {
    } else {
      return result;
    }
    let angle = this.getAngle(angx, angy);
    if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
      result = 3; // 向左
    } else if (angle >= -45 && angle <= 45) {
      result = 4; //向右
    }
    return result;
  }
  getAngle(angx: number, angy: number) {
    return (Math.atan2(angy, angx) * 180) / Math.PI;
  }
  toRecyle() {
    this.showRecycle = true;
  }
  timeFlag = 0;
  handleOpenDetail(id: any): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${id}`);
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
      query: { time: String(getNow()), id: `${id}` },
    });
  }
  async confirmRightNowGet() {
    let test: any = await this.checkRecycleReword();
    if (test.status == 2) {
      Toast.loading({ message: "操作失败...", forbidClick: true, mask: true });
      setTimeout(() => {
        Toast.clear();
        test.gift_status == 0
          ? (this.receiveState = true)
          : (this.receiveState = false);
        this.showGet = false;
        this.getRecycleStatus = true;
      }, 1000);
    } else {
      const res = await MemberApi.recycleReword(0).catch((err) => {
        return console.log(err);
      });
      Toast.loading({ message: "领取中...", forbidClick: true, mask: true });
      const timer = setInterval(async () => {
        this.timeFlag++;
        const { status } = await this.checkRecycleReword();
        if (status == 2) {
          this.showGet = false;
          let checkRecycleReword = await this.checkRecycleReword();
          if (checkRecycleReword.status == 2) {
            checkRecycleReword.gift_status == 0
              ? (this.receiveState = true)
              : (this.receiveState = false);
            this.getRecycleStatus = true;
            if (InteractionLib.isFlutter()) {
              InteractionLib.goldVipStatusNotice();
            }
          }
          Toast.clear();
          clearInterval(timer);
        }
        if (this.timeFlag == 10) {
          this.showGet = false;
          Toast.clear();
          clearInterval(timer);
        }
      }, 1000);
    }

    this.checkMember();
  }
  async confirmRecyle() {
    let test = await this.checkRecycleReword();
    if (test.status == 2) {
      Toast.loading({ message: "操作失败...", forbidClick: true, mask: true });
      setTimeout(() => {
        Toast.clear();
        test.gift_status == 0
          ? (this.receiveState = true)
          : (this.receiveState = false);
        this.showRecycle = false;
        this.getRecycleStatus = true;
      }, 1000);
    } else {
      const res = await MemberApi.recycleReword(1).catch((err) => {
        return err;
      });
      Toast.loading({ message: "回收中...", forbidClick: true, mask: true });
      const timer = setInterval(async () => {
        this.timeFlag++;
        const { status } = await this.checkRecycleReword();
        if (status == 2) {
          this.showRecycle = false;
          let checkRecycleReword = await this.checkRecycleReword();
          if (checkRecycleReword.status == 2) {
            checkRecycleReword.gift_status == 0
              ? (this.receiveState = true)
              : (this.receiveState = false);
            this.getRecycleStatus = true;
          }
          Toast.clear();
          clearInterval(timer);
        }
        if (this.timeFlag == 6) {
          this.showRecycle = false;
          Toast.clear();
          clearInterval(timer);
        }
      }, 1000);
    }
    this.checkMember();
  }
  checkTabC() {
    this.checkTabFlag = false;
  }
  checkTabA() {
    this.checkTabFlag = true;
  }
  saleList: any = [];

  async onLoad() {
    this.finished = false;
    this.page = this.page + 1;
    let res: any = await CapStoreApi.getHotItemList(
      this.MemberPageData.gold_vip_subject_id,
      this.page,
      this.page_size
    ).catch(() => []);
    this.loading = false;
    res.list.forEach((item: any) => {
      this.saleList.push(item);
    });
    if (res.list.length < 1) {
      this.finished = true;
    }
  }
  get MemberDate() {
    return `${momentFormat(
      this.MemberPageData.gold_vip_expire_time,
      true,
      "-"
    )} 到期`;
  }
  get expireShow() {
    const { month, year, day } = monthday(
      this.MemberPageData.gold_vip_expire_time
    );
    const now = new Date();

    if (year > now.getFullYear()) {
      return false;
    } else if (month > now.getMonth() + 1) {
      return false;
    } else if (day - now.getDate() > 7) {
      return false;
    } else if (day - now.getDate() <= 0) {
      return false;
    } else {
      return true;
    }
  }
  get isRenew() {
    const { month, year, day } = monthday(
      this.MemberPageData.gold_vip_expire_time
    );
    const now = new Date();
    if (year > now.getFullYear()) {
      return false;
    } else if (month > now.getMonth() + 2) {
      return false;
    } else if(day == now.getDate()&&month == now.getMonth()+1){
      return true;
    }else{
      return false
    }
  }
  get checkTab() {
    return this.checkTabFlag;
  }
  get checkFlagStyle1() {
    if (this.checkFlag1) {
      return {
        display: "none",
      };
    }
    return {
      display: "block",
    };
  }
  get checkFlagStyleBack1() {
    if (this.checkFlag1) {
      return {
        display: "none",
      };
    }
    return {
      display: "block",
    };
  }
  get checkFlagStyle2() {
    if (this.checkFlag2) {
      return {
        display: "none",
      };
    }
    return {
      display: "block",
    };
  }
  get checkFlagStyleBack2() {
    if (!this.checkFlag2) {
      return {
        display: "block",
      };
    }
    return {
      display: "none",
    };
  }
  list = [
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%85%8D%E8%B4%B9%402x.png",
      title: "免费大作",
      desc: "价值40元以上",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E7%A5%9E%E7%A7%98%402x.png",
      title: "神秘游戏",
      desc: "热门3A大作",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%9B%9E%E6%94%B6%402x.png",
      title: "稳赚回收",
      desc: "会费直接回本",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E9%AA%A8%E6%8A%98%402x.png",
      title: "天天骨折",
      desc: "会员特权抢",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E4%BC%9A%E5%91%98%E4%BB%B7%402x.png",
      title: "会员价",
      desc: "金会员专属价",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E8%92%B8%E6%B1%BD%E5%B8%81%402x.png",
      title: "消费返蒸汽币",
      desc: "省上加省",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%8B%8B%E7%AB%A0%402x.png",
      title: "专属勋章",
      desc: "金会员勋章",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x.png",
      title: "敬请期待",
      desc: "更多权益",
    },
  ];
  widthheight = {
    width: 20 + "px",
    height: 20 + "px",
  };
  supplyBox: Array<ISupplyBox> = [];
  supplyPackageState = getModule(SupplyPackageStateStore);
  tabWidth = "";
  tobuyit(item: any) {
    if (item.behavior_data.code == 4) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(item.behavior_data.data);
      }
      window.location.href = item.behavior_data.data;
    }
  }
  mounted() {
    observer("updateGoldBlindBoxStatus", async (e: any) => {
      // 处理补给箱状态
      let checkSuccessBlind: any = await this.checkSuccessBlind();
      if (checkSuccessBlind.status == 2) {
        this.supply_id = checkSuccessBlind.supply_id;
        this.getBlindStatus = true;
      } else {
        this.getBlindStatus = false;
      }
    });
    setTimeout(()=>{
      InteractionLib.setWebViewTitle("会员中心");
    },3000)
  }
  receiveState = false;
  async created() {
    Toast.loading({ message: "加载中...", forbidClick: true, mask: true });
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) / 2 - 12;
    this.tabWidth = (clientWidth || offsetWidth) - 104 - (42 + 35) + "px";
    this.widthheight = {
      width: this.wideHigh + "px",
      height: this.wideHigh + "px",
    };
    let res: any = await CapStoreApi.getHotItemList(
      this.MemberPageData.gold_vip_subject_id,
      this.page,
      this.page_size
    ).catch(() => []);
    this.saleList = res.list;
    this.supplyBox = await this.supplyPackageState.getSupplyBoxList(
      this.MemberPageData.gold_vip_box_id
    );
    let checkSuccessBlind: any = await this.checkSuccessBlind();

    if (checkSuccessBlind.status == 2) {
      this.supply_id = checkSuccessBlind.supply_id;
      this.getBlindStatus = true;
    }

    let checkRecycleReword = await this.checkRecycleReword();

    if (checkRecycleReword.status == 2) {
      //0领取 1回收
      checkRecycleReword.gift_status == 0
        ? (this.receiveState = true)
        : (this.receiveState = false);
      this.getRecycleStatus = true;
    } else {
      this.getRecycleStatus = false;
    }
    Toast.clear();
    setTimeout(() => {
      InteractionLib.setWebViewTitle("会员中心");
    }, 2000);
  }
  @Emit()
  checkMember() {
    return;
  }
  openOrClose() {
    this.checkFlag1 = !this.checkFlag1;
  }
  openOrClose2() {
    this.checkFlag2 = !this.checkFlag2;
  }
}
